<template>
  <article>
    <div v-if="block.item.title && (showImage || showEmbed)" class="ag-block__media">
      <span v-if="showImage" class="ag-block__image">
        <Image :media="block.item.featured_image" conversion="cover" lazy responsive :alt="block.item.title" />
      </span>
      <div v-if="showEmbed" class="ag-block__embed" :class="`ag-block__embed--${block.item.embed?.type}`" v-html="block.item.embed?.code" ref="embedRef"></div>
    </div>

    <div>
      <h3 v-show="showTitle" class="ag-block__title ag-featured-content__title">
        <a
          v-if="!isAudioOrVideo"
          :href="block.item.url"
          rel="nofollow noindex"
          target="_blank"
          v-html="$markdown(block.item.title)"
          @click.prevent="onClick(block)"
        />
        <span v-else>{{ block.item.title }}</span>
      </h3>

      <p v-if="showDescription" class="ag-block__description">
        <strong v-if="showLeadIn" class="ag-block__lead-in">{{ block.item.lead_in }}</strong>
        <span v-if="showSynopsis" class="ag-block__description" v-html="' ' + block.item.synopsis"></span>
        <em v-if="showAuthor" class="ag-block__author"> By {{ block.item.author }}.</em>
        <em v-if="showRuntime" class="ag-block__runtime"> Runtime: {{ block.item.runtime }} min.</em>
      </p>
    </div>
  </article>
</template>

<script setup lang="ts">
const props = defineProps<{
  block: ContentBlock;
  customisations: Customisations;
}>();

const { content_author_enabled, content_image_enabled, content_lead_in_enabled, content_runtime_enabled, content_synopsis_enabled, content_title_enabled } =
  toRefs(props.customisations);

const showTitle = computed(() => content_title_enabled.value && props.block.item.title && props.block.item.url);
const showDescription = computed(() => showLeadIn.value || showSynopsis.value || showAuthor.value || showRuntime.value);
const showLeadIn = computed(() => content_lead_in_enabled.value && props.block.item.lead_in);
const showRuntime = computed(() => content_runtime_enabled.value && props.block.item.runtime);
const showAuthor = computed(() => content_author_enabled.value && props.block.item.author);
const showSynopsis = computed(() => content_synopsis_enabled.value && props.block.item.synopsis);
const showImage = computed(() => content_image_enabled.value && props.block.item.featured_image && !props.block.item.embed);
const showEmbed = computed(() => props.block.item.embed && props.block.item.embed.type && props.block.item.embed.code);

const isAudioOrVideo = [4, 8].includes(props.block.item.type?.id);

const embedRef = ref<HTMLElement>();

onMounted(() => {
  const el = embedRef.value?.querySelector("iframe");

  if (el) {
    const { mountAnalytics } = useAnalytics();
    mountAnalytics(el, props.block);
  }
});

const { onClick } = useBlock();
</script>

<style lang="postcss">
.ag-theme--classic-grid .ag-block--featured-content {
  @apply flex flex-col;
  @apply md:flex-row md:items-center;

  .ag-block__media {
    @apply flex-shrink-0;
    @apply md:w-1/2;
    @apply xl:w-2/3;
  }

  .ag-block__image {
    @apply flex justify-center;
  }

  .ag-block__embed {
    @apply mt-0;

    iframe {
      @apply w-full;
    }
  }

  .ag-block__title {
    @apply m-0;
  }

  .ag-block__description {
    @apply mb-0 mt-2;
  }

  .ag-block__featured-image {
    @apply mr-6 flex-shrink-0;
  }
}
</style>
